/* Chat Ui */
.rcw-conversation-container {
    box-shadow: none !important;
}

.rcw-conversation-container>.rcw-header {
    background-color: #1C1A23 !important;
    padding: 5px 10px !important;
    text-align: left !important;
    border-radius: 0 !important;
}

.rcw-conversation-container .rcw-title {
    font-size: 18px !important;
    margin: 0 !important;
    padding: 10px 0 !important;
    color: #fff;
}

.rcw-client .rcw-message-text {
    background-color: #312E41 !important;
    padding: 5px 10px !important;
    color: #E3DCFD !important;
}

.rcw-response .rcw-message-text {
    background-color: #312E41 !important;
    padding: 5px 10px !important;
    color: #8F87B0 !important;
}

.rcw-messages-container {
    background-color: #252232 !important;
    box-shadow: none !important;
    overflow-y: auto !important;
}

.rcw-new-message {
    background-color: #252232 !important;
    color: #fff !important;
}

.rcw-sender {
    background-color: #312E41 !important;
    align-items: center !important;
}

.rcw-picker-btn,
.rcw-send {
    background-color: #312E41 !important;
}

.rcw-timestamp {
    color: #fff !important;
}

.rcw-launcher {
    /* width: 40px!important;
    height: 40px!important; */
    background-color: inherit !important;
    box-shadow: none !important;
}

@media screen and (max-width: 800px) {
    .rcw-conversation-container .rcw-close-button {
        background-color: inherit !important;
        width: 30px !important;
    }

    .rcw-widget-container {
        height: 80vh !important;
        z-index: 999 !important;
    }

    .rcw-conversation-container {
        max-width: 100vw !important;
    }
}

.rcw-widget-container {
    z-index: 999 !important;
    right: auto !important;
    left: 0 !important;
    margin-bottom: 70px !important;
}

.rcw-launcher {
    z-index: 10 !important;
    right: auto !important;
    left: 0 !important;
    display: none;
}

.recording-icon-wrapper {
    color: white;
    height: 23px;
    font-size: 10px;
    cursor: pointer;
    position: absolute;
    z-index: 12;
    background: #1F1C2A;
    display: flex;
    left: 75px;
    top: 29px;
    padding: 4px 7px 3px 5px;
    border-radius: 15px;
}

.recording-icon {
    color: #EE4242;
    margin-right: 2px;
    margin-top: -2px;
    font-size: 15px;
    display: flex;
    align-items: center;
    font-weight: bold;
}