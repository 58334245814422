.modalWidth {
    width: 835px;
    /* height: 449px; */
    background: #1F1C2B;
    box-shadow: -10px -10px 30px #312e41, 0px 60px 100px #0e0c14;
    border-radius: 10px;
    left: 16%;
    bottom: 125px;
    position: absolute;
    padding: 20px;
    border: 1px solid #1F1C2B !important;
}

.iicons {
    color: #E3DCFD;
    border: 1px solid #E3DCFD;
    padding: 0px 7px;
    font-size: 10px;
    border-radius: 100px;
    margin-left: auto;
}

.cardDiv {
    width: 200px;
    height: 120px;
    background: #252232;
    box-shadow: 4px 4px 10px #000000;
    border-radius: 10px;
    position: relative;
}

.numberContent {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #E3DCFD;
    padding: 10px;
}

.up {
    position: absolute;
    width: 40px;
    height: 40px;
    right: -18px;
    top: 41px;
    padding-top: 8px;
    text-align: center;
    background: linear-gradient(306.33deg, #252232 11.29%, #383348 86.76%);
    box-shadow: 4px 4px 10px #14121C;
    border-radius: 100px;
}

.fa-chevron-down {
    color: #E3DCFD;
}

.fa-chevron-up {
    color: #E3DCFD;
}

.down {
    position: absolute;
    width: 40px;
    height: 40px;
    left: -18px;
    padding-top: 8px;
    text-align: center;
    top: 41px;
    color: #E3DCFD;
    background: linear-gradient(306.33deg, #272334 11.29%, #383348 86.76%);
    box-shadow: 4px 4px 10px #14121c;
    border-radius: 100px;
}

.CardTitles {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    margin-top: 16px;
    line-height: 30px;
    text-align: center;
    color: #E3DCFD;

}

.numbers {
    color: #E3DCFD;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;
    margin-left: 3px;
}


.range-slider {
    width: 50%;
    margin-left: 17px;
    position: relative;
    margin-top: 52px;
    margin-bottom: 2rem;
    text-align: center;
}

.range-slider1 {
    width: 90%;
    margin-left: 17px;
    position: relative;
    margin-top: 14px;
    margin-bottom: 2rem;
    text-align: center;
}

#range {
    -webkit-appearance: none;
    width: 100%;
}

#range:focus {
    outline: none;
}

#range::before, #range::after {
    position: absolute;
    top: 13px;
    color: #14121E;
    /* box-shadow: 5px 5px 15px #14121E, inset 10px 10px 10px rgba(0, 0, 0, 0.1), inset -5px -5px 8px rgba(255, 255, 255, 0.2); */
    font-size: 15px;
    line-height: 1;
    padding: 3px 5px;
    background: #1f1c2b;
    border-radius: 5px;
}

#range::before {
    left: -2px;
    content: attr(data-min);
}

#range::after {
    right: -2px;
    content: attr(data-max);
}

#range::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: #18151F;
    box-shadow: inset -2px -2px 4px #312E41;
    border-radius: 31px;
}

#range::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #E90DB9;
    border-radius: 50%;
    background: #2B2837;
    box-shadow: 5px 5px 15px #14121E, inset 10px 10px 10px rgba(0, 0, 0, 0.1), inset -5px -5px 8px rgba(255, 255, 255, 0.2);
    cursor: pointer;
    height: 22px;
    width: 22px;
    transform: translateY(calc(-50% + 2px));
}

.sliderB {
    position: absolute;
    width: 40px;
    height: 40px;
    left: -36px;
    top: -4px;
    padding-top: 6px;
    color: #E3DCFD;
    background: #252232;
    box-shadow: -4px -4px 9px #373348, 4px 4px 4px #1b1826;
    border-radius: 40px;
}

.sliderhash {
    position: absolute;
    width: 40px;
    height: 40px;
    right: -40px;
    padding-top: 6px;
    top: -4px;
    color: #E3DCFD;
    background: #252232;
    box-shadow: -4px -4px 9px #373348, 4px 4px 4px #1B1826;
    border-radius: 40px;
}

.m50 {
    margin: 50px;
}

.StandardNote div {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #FF08CC;
    text-align: center;
    padding: 12px 12px;
}

.CustomNote div {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #E3DCFD;
    padding: 12px 12px;
}

.StandardNote {
    background: #252232;
    box-shadow: inset -5px -5px 16px #322E46, inset 4px 4px 15px #000000;
    border-radius: 25px;
    text-align: center;
    width: 140px;
    height: 40px;

}

.CustomNote {
    background: #252232;
    box-shadow: -4px -4px 9px #373348, 4px 4px 4px #1B1826;
    border-radius: 20px;
    text-align: center;
    width: 140px;
    height: 40px;
}

.mt-46 {
    margin-top: 46px;
}

.dropWidth {
    margin-top: 12%;
    height: 40px;
    background: #252232;
    box-shadow: -5px -5px 30px #423e58, 4px 4px 20px #000000;
    border-radius: 25px;
    padding: 16px 20px;
}

.dropContent {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #E3DCFD;
}

.chevronDown .fa-chevron-down {
    color: #E3DCFD;
    font-size: 12px;
}

.SaveDone {
    width: 136px;
    height: 40px;
    background: #252232;
    box-shadow: -4px -4px 9px #373348, 4px 4px 4px #1B1826;
    border-radius: 20px;
}

.done {
    width: 126px;
    height: 35px;
    margin: 2px 0px 2px 5px;
    text-align: center;
    background: #FF08CC;
    border-radius: 20px;
    font-family: 'Poppins';
    padding-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    color: #E3DCFD;
}

.ReactModal__Overlay {
    z-index: 10 !important;
}

.sarigama {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #645B88;
}

.saWords {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #E3DCFD;
    margin-top: 9px;
}

.sarigamaWordsWith {
    padding: 30px 27px 10px 27px;
}

@media(max-width:568px) {
    .row>* {
        max-width: 100% !important;
        padding: 0px 35px 0px 35px !important;
    }

    .modalWidth {
        width: 100%;
        height: 100%;
        background: #1F1C2B;
        box-shadow: -10px -10px 30px #312e41, 0px 60px 100px #0e0c14;
        border-radius: 0px;
        left: 0px;
        bottom: 0px;
        position: absolute;
        padding: 0px;
        border: 1px solid #1F1C2B !important;
    }

    .m50 {
        margin: 35px 10px 0px 10px;
        text-align: -webkit-center !important;
    }

    .mobileMa {
        display: none;
    }

    .range-slider1 {
        width: 73%;
        margin-left: -1px;
        margin-bottom: 6rem;
    }

    .SaveStop {
        width: 136px;
        height: 40px;
        background: #252232;
        box-shadow: -4px -4px 9px #373348, 4px 4px 4px #1B1826;
        border-radius: 20px;
        color: #E3DCFD;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
    }

    .Stop {
        padding: 10px;
    }

    .mt5 {
        margin: 0px 30px 15px 30px;
    }

    .dropWidth {
        margin-bottom: 27%;
    }

    .header {
        background: #1F1C2B;
        border: 1px solid #282533;
        box-shadow: 0px 60px 100px #0E0C14;
        height: 80px;
    }

    .headerPt {
        padding: 27px 15px 0px 15px;
        justify-content: space-between;
    }

    .iicons {
        border: 2px solid #E3DCFD;
        padding: 0px 9px;
        font-size: 13px;
        font-weight: 600;
        margin-left: inherit;
    }

    .fa-long-arrow-left {
        color: #E3DCFD;
        font-size: 22px;
        font-weight: 600;
    }

    .Title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        color: #E3DCFD;
    }
}