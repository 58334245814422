.pageBackround
{
    background-color: #1F1C2B;
    position: relative;
}
.initial-Cirle{
    position: absolute;
    top: 40%;
    border: 1px solid #E317BA;
    border-radius: 100%;
    font-size: 31px;
    font-weight: 500;
    padding: 15px 20px;
    background: #E317BA;
    left: 45%;
}
@media(max-width:568px)
{
    .initial-Cirle {
        position: absolute;
        top: 40%;
        border: 1px solid #E317BA;
        border-radius: 100%;
        font-size: 50px;
        font-weight: 500;
        padding: 19px 26px;
        background: #E317BA;
        left: 39%;
    }
}