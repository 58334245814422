* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

.mainDiv {
    position: relative;
}

.teacherVideo img {
    height: 100vh;
}

.icon-holder-primary {
    background: #E82C86;
    width: 36px;
    height: 36px;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-holder-secondary {
    background: #1C1A23;
    width: 36px;
    height: 36px;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-img {
    width: 18px !important;
    height: 18px !important;
}

.chat-badge {
    position: absolute;
    background-color: red;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    top: 5px;
    margin-left: 25px;
    min-width: 19px;
    min-height: 19px;
    text-align: center;
    line-height: 19px;
    border-radius: 50%;
}

.help-icon-wrapper {
    color: white;
    height: 32px;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    left: 20px;
    padding: 6px 7px 5px 5px;
    border-radius: 15px;
    border: 3px solid #e82c86;
}

.help-icon {
    padding: 0px 5px;
    color: #e82c86;
    border-radius: 15px;
    border: 3px solid #e82c86;
    margin-right: 2px;
}

.recording-popup {
    position: fixed;
    background: white;
    padding: 10px;
    margin-top: -90px;
    margin-left: -150px;
}

.device-arrow {
    color: black !important;
    font-size: 11px;
    padding-right: 10px;
}

.backRoundImg {
    position: absolute;
    width: 100%;
    height: 100vh;
    min-width: 730px !important;
    /* left: -8px;
    top: -4px; */
}

.logoImg {
    position: absolute;
    padding: 6px 11px 6px 17px;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;
    left: 15px;
    top: 14px;
    border-radius: 20px;
}

.logoImg img {
    width: 35px;
    height: 35px;
}

.studentVideo {
    position: absolute;
    left: 656px;
    bottom: 89px;
    text-align: center;
    width: 50%;

}

.studentVideo Img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin: 10px 0px;
}

.icons {
    position: absolute;
    /* width: 586px; */
    height: 60px;
    background: #312E41;
    border: 1px solid #312E41;
    border-radius: 20px;
    display: flex;
    z-index: 10;
    align-items: center;
    justify-content: center;
    /* padding: 5px 25px; */
}

.icons .links {
    color: #DED7F8;
    /* font-size: 24px; */
    padding: 5px 15px;
    cursor: pointer;
    /* line-height: 18px; */
}

.icons .links img {
    width: 24px;
    height: 24px;
}

.icons .links .fullscreen {
    width: 19px !important;
    height: 18px !important;
}

.icons .links .linkContent {
    color: #E3DCFD;
    padding-top: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}

.text-center {
    text-align: center;
}

.teacherVideo-player div {
    position: inherit !important;
}

.studentVideo-player div {
    position: inherit !important;
}

.backRoundImg .teacherVideo-container :nth-child(2) {

    width: 30%;
    height: 30%;
    position: absolute;
    right: 24px;
    bottom: 76px;
    object-fit: cover;
    border-radius: 50px;
}

.video-call-container {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    height: calc(100vh - 60px);
    align-items: flex-end;
    /* background-color: #626161; */
}

.video-call-container .teacherVideo-container {
    margin: 5px 20px 20px 5px;
    position: relative;
    width: 330px;
    bottom: 0px;
    z-index: 9;
    height: 189px;
    right: 0px;
    border-radius: 0px;
    overflow: hidden;

}

/* .video-call-container .teacherVideo-container:nth-child(1) {
    border-radius: 25px;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 75%);
    bottom: 8px;
    right: 18px;
} */

/* .video-call-container .teacherVideo-container:nth-child(3) {
    border-radius: 25px;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 75%);
    bottom: 8px;
    right: 18px;
} */

.video-call-container .teacherVideo-container:nth-child(1) {
    margin: 0px 0px;
    position: absolute;
    height: 100vh;
    width: 100%;
    /* z-index: 8; */
}

.video-call-container .fullVideo-container {
    margin: 0px 0px;
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 8;
}

.icon-container {
    display: flex;
    z-index: 10;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    height: 60px;
    background: #312e41;
}

.close-btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ded7f8;
}

.close-btn i {
    margin-right: 5px;
    font-size: 20px;
}

.min-btn {
    z-index: 9;
    right: 42px !important;
    color: #fff;
    top: 5px;
    width: 10px !important;
    height: 10px !important;
}

.video-call-container .teacherVideo-container:nth-child(2) .min-btn {
    display: none;
}

.minmax-btn {
    background: #E3DCFD;
    border: 2px solid #E3DCFD;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.74);
}

.popup-record {
    position: relative;
}

.popup-record .ant-popover.ant-popconfirm.ant-popover-placement-top {
    top: -126px;
    width: 300px;
    left: -94px;
}

@media(max-width:568px) {
    .logoImg {
        width: 90%;
        left: 20px;
    }

    .mobileViewHide {
        display: none;
    }

    .backRoundImg {
        width: 100%;
        height: 100vh;
        /* height: 650px; */
        left: 0;
        top: 0;
        background-size: 100% 100vh;
    }

    .studentVideo {
        left: 0;
        bottom: 76px;
        width: 100%;
    }

    .studentVideo Img {
        width: 102px;
        height: 79px;
    }


    .icons {
        /* width: 100%; */
        height: 60px;
        /* left: 0px; */
        bottom: 0px;
        padding: 5px 5px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        /* text-shadow: 2px 2px #171111; */
    }

    .icon-container {
        bottom: 0px;
    }

    .icons .links {
        font-size: 24px;
        padding: 2px 12px;
        /* padding: 2px 34px; */
    }

    .hide {
        display: none;
    }

    .row>* {
        max-width: 33.33% !important;
        padding: 0px 15px !important;
    }

    .video-call-container .teacherVideo-container {
        bottom: inherit;
        /* 74px */
        height: 45vh !important;
        /*100vh */
        top: 11px !important;
        right: 2px !important;
        width: 99% !important;
        border-radius: 15px;
        box-shadow: 2px 2px 5px rgb(0 0 0 / 75%);
    }

    .video-call-container .teacherVideo-containerMobileSingleView {
        bottom: inherit;
        height: 100vh !important;
        top: 11px !important;
        right: 2px !important;
        width: 99% !important;
        border-radius: 15px;
        box-shadow: 2px 2px 5px rgb(0 0 0 / 75%);
    }


    .logoImg img {
        width: 35px;
        height: 35px;
    }

    .close-btn span {
        display: none;
    }

    .close-btn i {
        font-size: 25px;
        color: #E3DCFD;
    }

    .Minimize {
        right: 75px !important;
    }

    .bottomMaxbtn {
        bottom: 90px !important;
        width: 20% !important;
        right: 17px !important;
    }

    .SmallView {
        width: 100% !important;
        height: 75vh !important;
    }

    .SmallViewsingleVideo {
        width: 98% !important;
        right: 4px !important;
        bottom: 86px !important;
        height: 39vh !important;

    }

    .SmalViewContainer {
        width: 75px;
        height: 24vh;

    }

    .SmallViewsingleVideo .SmalViewContainer {
        width: 100% !important;
    }

    .share-player {
        width: 100% !important;
        height: 15% !important;
    }
}

@media(min-width:1400px) {
    .icons .links .linkContent {
        font-size: 17px;
        line-height: 15px;
    }

    /* .icons .links img {
        width: 34px;
        height: 30px;
    } */

    .links i {
        font-size: 26px;
    }

    .icons .links .fullscreen {
        width: 24px !important;
        height: 24px !important;
    }

    .bottomMaxbtn {
        width: 78px !important;
    }
}


/* New design */
.parentDiv {
    position: relative;
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end; */
    height: calc(100vh - 60px);
    /* align-items: flex-end; */
    background-color: #202124;
}

.LargeView {
    width: 100%;
}

.SmallViewsingleVideo {
    width: 26%;
    right: 15px;
    display: flex;
    background: #707072bf;
    bottom: 18px;
    position: absolute;
    overflow-y: auto;
    height: 34vh;
    padding: 10px 0px 17px 0px;
    z-index: 9;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
}

.SmallView {
    /* background: linear-gradient(0deg, #EDEBEF, #EDEBEF);
    box-shadow: 4px 4px 10px #beb4c8, -4px -4px 10px #ffffff;
    border-radius: 10px; */
    width: 26%;
    right: 15px;
    display: flex;
    top: 77px;
    position: absolute;
    overflow-y: auto;
    height: 85vh;
    background: #312e41bf;
    padding: 10px 0px 17px 0px;
    z-index: 9;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
}

.SmalViewContainer {
    position: relative;
    top: 10px;
    border: 1px solid;
    left: 0px;
    margin: 10px 10px;
    width: 100%;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 2px 2px 5px #8f8d8d;
    background: #312e41bf;
}

.Smal4ViewContainer {
    width: 150px;
}

.Smal5ViewContainer {
    width: 100%;
}

.Smal6ViewContainer {
    width: 150px;
}

.Minimize {
    font-weight: 800;
    font-size: 14px;
    top: -1px;
    right: 7px;
    position: absolute;
    color: #ded7f8;
    cursor: pointer;
    z-index: 10;
}

.bottomMaxbtn {
    position: absolute;
    bottom: 22px;
    background: #312E41;
    cursor: pointer;
    text-align: center;
    /* align-items: center; */
    /* display: flex !important; */
    width: 4%;
    height: 91px;
    border-radius: 20px;
    padding: 34px 10px 10px 10px;
    right: 70px;
    color: #E3DCFD;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    z-index: 9;
}

.fa-users {
    font-size: 20px;
}

.fa-window-minimize {
    font-size: 20px;
    margin: 0px 5px 10px;
}

.participantNo {
    position: absolute;
    right: -4px;
    top: -11px;
    background: #FF139E;
    border: 1px solid #FF139E;
    padding: 0px 4px 0px 2px;
    border-radius: 50px;
    color: #E3DCFD;

}

.participantGroup {
    position: relative;
}

.dblock {
    display: block;
}

@media only screen and (max-width: 767px) {
    .SmallView {
        width: 98% !important;
        height: 39% !important;
        overflow: hidden;
        right: 5px;
        bottom: 87px;
        top: inherit;
    }

    .SmalViewContainer {
        bottom: 0;
        width: 44%;
        height: 30vh;
    }

    .Minimize {
        right: 20px !important;
    }

    .share-player {
        width: 100% !important;
        height: 15% !important;
    }

    .video-call-container .teacherVideo-container .teacherVideo-player .agora_video_player {
        object-fit: contain !important;
    }

    .video-call-container .teacherVideo-containerMobileSingleView .teacherVideo-player .agora_video_player {
        object-fit: contain !important;
    }
}

@media only screen and (min-width: 768px) {
    .share-player {
        width: 43% !important;
        height: 7% !important;
    }
}

.share-player {
    position: absolute;
    right: 0;
    margin: 0 auto;
    left: 0;
    top: 79px;
    bottom: inherit;
    object-fit: cover;
    background: #312e418c;
    border-radius: 50px;
    padding: 10px 10px;
    text-align: center;
    color: white !important;
}

.small-modalWidth {
    position: absolute;
    height: fit-content;
    border-radius: 20px;
    padding: 18px;
    margin: 0 auto;
    width: 280px;
    left: 37%;
    ;
    bottom: 125px;
    background: #252232;
}

.agora_video_player {
    object-fit: contain !important;
}

.getFastboard {
    display: flex;
    height: 82%;
    position: absolute;
    top: 60px;
    left: 16px;
}