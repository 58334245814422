.setting-mask {
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #1f1c2aba;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.setting-wrapper {
  max-width: 606px;
  width: 90%;
  position: relative;
  color: #1F1C2A;
  /* display: flex;
    align-items: start; */
  background: #E3DCFD;
  height: 432px;
  border-radius: 8px;
}

.setting-close {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
}

.settings-tit {
  padding: 5px 15px;
}

.setting-container {
  display: flex;
  height: 100%;
}

.setting-left {
  width: 30%;
  border-right: 2px solid #bfb7dd;
}

.setting-right {
  width: 70%;
}

.setting-menu-item {
  display: flex;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 10px 10px 0px;
}

.setting-menu-icon {
  padding: 0px 15px;
  width: 45px;
  height: auto;
}

.setting-active {
  background: #D5CAFC;
  color: #E82C86;
}


/***********audio-setting-start*************/
.audio-lable {
  color: rgb(232, 44, 134);
  font-weight: bold;
  margin-bottom: 8px;
}

.audio-select {
  border: 2px solid black;
  border-radius: 10px;
  background: #e3dcfd;
  font-weight: bold;
}

/**************** background ***********/
.background-container {
  display: flex;
  justify-content: space-between;
}

.background-container>div {
  width: 115px;
  text-align: center;
  height: 75px;
  font-size: 23px;
  /* background: #D5CAFC; */
  border-radius: 7px;
  background-size: contain;
  cursor: pointer;
}

.blur-background {
  background: url('../../assets/img/icon/blur_background.svg');
}

.remove-background {
  background: url('../../assets/img/icon/remove_background.svg');
}

.set-background {
  background: url('../../assets/img/background.png');
}

.active-background {
  border: 2px solid #e82c86;
}


.fastboard-mask {
  position: fixed;
  z-index: 11;
  height: 100%;
  width: 100%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #1f1c2aba;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fastboard-wrapper {
  max-width: 606px;
  width: 90%;
  position: relative;
  color: #1F1C2A;
  /* display: flex;
    align-items: start; */
  background: #E3DCFD;
  height: 432px;
  border-radius: 8px;
}

.fastboard-close {
  position: absolute;
  right: -21px;
  top: -20px;
  background: #e3dcfd;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.fastboard-container {
  display: flex;
  height: 100%;
}