.thems {
    background: rgb(16, 1, 51);
    font-family: 'Poppins';
    font-style: normal;
}

.header_Logo {
    display: flex;
    align-items: center;
    padding: 10px;
}

.logoName {
    color: #FFFFFF;

}

.artium {
    font-size: 40px;
    padding-top: 10px;
    padding-left: 5px;
}

.academy {
    font-size: 16px;
    padding-left: 48px;
}

.parent_Div {
    position: relative;
}

.parent_Div img {
    width: 250px;
    height: 300px;
}

.artiumImg {
    position: absolute;
    top: 220px;
    left: 31%;
    display: flex;
    align-items: center;
}

.bodyContent {
    padding-left: 40px;
}

.nameText {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #E3DCFD;
}

.nameTextSubContent {
    font-size: 20px;
    line-height: 40px;
    color: #E3DCFD;
}

.Text {
    font-size: 14px;
    line-height: 14px;
    color: #E3DCFD;
    padding: 10px 0px
}
.dateTime
{
    display: flex;
    align-items: center;
    color: #8E86AF;
    font-size: 12px;
    line-height: 12px;
    padding-top:12px;
}
@media(max-width:568px)
{
    .artiumImg
    {
        left: 0;
        top:180px;
    }
    .parent_Div img {
        width: 126px;
        height: 252px;
    }
    .nameText {
        font-size: 20px;
        line-height: 20px;
    }
    .nameTextSubContent {
        font-size: 14px;
        line-height: 24px;
    }
    .Text {
        font-size: 14px;
        line-height: 20px;
    }
}

.waiting-mask {
    position: fixed;
    z-index: 11;
    height: 100%;
    width: 100%;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #1F1C2A;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .wrapper{
    width: 100%;
    color: #E3DCFD;
    display: flex;
    align-items: center;
  }

  .img {
    width: 10rem;
  }

  .logo_wrapper{
    width: 50%;
    display: inline-block;
    text-align: right;
    padding-right: 1rem;
  }
  .text_wrapper{
    width: 50%;
    display: inline-block;
  }

  .text_header{
    color: #E3DCFD;
  }

  .wait_text{
    font-size: 1.2rem;
    font-weight: 300;
  }
  .text{
    margin-bottom: 0.5rem;
  }
  .ping_text{
    color:#E217B9;
  }
  .small_text{
    font-size: 0.7rem;
    color: #8E86AF;
  }

  @media screen and (max-width: 600px){
    .logo_wrapper{
        width: 30%;
        padding-right: 0px;
      }
      .text_wrapper{
        width: 70%;
      }
} 