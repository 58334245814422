.modalWidth {
    width: 312px !important;
    left: 37%;;
    bottom: 125px;
    background: #252232;
    border-radius: 20px;
    position: absolute;
    padding: 20px;
}
.setting-modal-width {
    width: 640px;
    height: fit-content;
    background: #1F1C2B;
    box-shadow: -10px -10px 30px #312e41, 0px 60px 100px #0e0c14;
    border-radius: 10px;
    margin: auto;
    position: absolute;
    top: 50px;
    left: 50px;
    bottom: 50px;
    right: 50px;
    padding: 50px 20px;
    border: 1px solid #1F1C2B !important;
}
@media (max-width: 820px){
    .setting-modal-width{
        width:auto;
    }
}
.d-flex {
    display: flex;
    align-items: center;
}

.title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #E3DCFD;
}

.SwarTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #1F1C2A;
    margin-right: 92px;
}

.mt-20 {
    margin-top: 20px;
}

.LyricsTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #E3DCFD;
}

.fa-circle {
    color: #E3DCFD !important;
}

.fa-chevron-right {
    color: #E3DCFD !important;
    font-size: 11px;
    padding-right: 10px;
}

.device-arrow {
    color: black !important;
    font-size: 11px;
    padding-right: 10px;
  }

.line {
    background: #252232;
    box-shadow: inset -2px -2px 3px #423e58, inset 2px 2px 4px #000000;
    border-radius: 25px;
    height: 6px !important;
}

@media(max-width:568px) {
    .modalWidth {
        width: 100% !important;
        left: 0px;
        bottom: 0px;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .ReactModal__Overlay {
        z-index: 10 !important;
    }
} 
.closebtn{
    position: absolute;
    right: 10px;
    top: 10px;
}